import Alpine from 'alpinejs';
import { mobileMenu } from '../alpine-data/MobileMenu';

(window as typeof window & { Alpine: typeof Alpine }).Alpine = Alpine;

document.addEventListener('alpine:init', () => {

  mobileMenu()

});

Alpine.start();

