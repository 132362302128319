import Alpine from 'alpinejs';

export const mobileMenu = () => Alpine.data('menu', function() {
  return {
    open: false,
    toggle() {
      this.open = !this.open
    }
  }
})

